.layout {
  position:absolute;
  width: 100vw;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  color: #34303E;
  background: #FAFBFF;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;

  .menu-mob-land {
    display: none;
  }

  .header {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    height: 72px;
    background: #FAFBFF;
    border-bottom: 1px solid #F1F3F7;
    padding-left: 32px;
    padding-right: 32px;
    justify-content: space-between;
    transition:  transform .3s linear;
    z-index: 150;
    overflow: hidden;

    .menu-btn-mob-land {
      display: none;
    }

    .exit-btn-mob-land {
      display: none;
    }

    .group-btns {
      display: flex;
      flex-direction: row;
      gap: 32px;
      height: 100%;

      .signIn-btn {
        margin-top: 17px;
        width: 152px;
        height: 40px;
        border: 0;
        background: #1F83FE;
        box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        cursor: pointer;
      }

      .signIn-btn:hover {
        background: #57A2FE;
      }

      .phone-btn-nav {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        background: #FAFBFF;
        border: 0;
        // cursor: pointer;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        color: #34303E;
      }

      .items-nav {
        background: #FAFBFF;
        border: 0;
        cursor: pointer;
        font-size: 14px;
        line-height: 120%;
        height: 100%;
      }

      .items-nav:hover {
        color: #1F83FE;
        border-bottom: 3px solid #1F83FE;
      }
    }
  }

  .header.hide {
    transform: translateY(-72px);
  }

  .main {
    width: 100%;
    height: 799px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .back-img-first {
      position: absolute;
      right: 0px;
      top: 0px;
      pointer-events: none;
    }

    .back-img-big {
      display: none;
    }

    .back-img-first-mob {
      display: none;
    }

    .back-img-analit {
      position: absolute;
      top: 186px;
      right: 8px;
      pointer-events: none;
    }

    .back-img-analit-mob {
      position: absolute;
      top: 265px;
      right: 428px;
      pointer-events: none;
    }

    .works-img {
      position: absolute;
      top: 245px;
      right: 468px;
      width: 190px;
      height: 50px;
      padding-left: 16px;
      background: #ffffff;
      backdrop-filter: blur(40px);
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #1F83FE;
      line-height: 120%;
      box-shadow: 2px 2px 2px 2px rgba(211, 208, 208, 0.15);
      pointer-events: none;

      .person {
        width: 20px;
        padding-right: 3.5px;
        padding-left: 3.5px;
        height: 20px;
        background: rgba(31, 131, 254, 0.1);
        border-radius: 5px;
      }
    }

    .coll-img {
      background-color: #ffffff;
      backdrop-filter: blur(40px);
      border-radius: 8px;
      box-shadow: 2px 2px 2px 2px rgba(211, 208, 208, 0.15);
      position: absolute;
      top: 184px;
      right: 44px;
      width: 178px;
      height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #1F83FE;
      padding-left: 8px;
      gap: 10px;
      font-weight: 400;
  font-size: 14px;
  pointer-events: none;
    }

    .leftContent {
      width: 40%;
      position: absolute;
      top: 240px;
      left: 4%;
      color: #34303E;
      font-size: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .h1 {
        font-weight: 900;
        font-size: 50px;
        line-height: 120%;
      }

      button {
        margin-top: 7px;
        width: 177px;
        height: 40px;
        background: #1F83FE;
        box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
        border-radius: 8px;
        border: 0;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        cursor: pointer;
      }
      button:hover {
        background: #57A2FE;
      }
    }

    .logos {
      position: absolute;
      top: 650px;
      left: 166px;
      right: 166px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .top-lane {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }
      .bot-lane {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        gap: 25px;
      }
    }
  }
  .swipe-list {
    height: auto;
    width: 100%;
    background: #FAFBFF;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    gap: 32px;
    overflow: hidden;
    padding-bottom: 50px;

    h1 {
      padding-left: 32px;
    }

    p {
      width: 824px;
      font-size: 24px;
      line-height: 140%;
      padding-left: 32px;
    }
  }

  .Slider {
    padding-left: 32px;
    height: auto;
    overflow: hidden;
  }

  .slideHorizontal___1NzNV {
    padding-bottom: 155px !important;
    overflow: hidden;
  }

  .focusRing___1airF.carousel__slide-focus-ring { outline: none !important; }

  .galleryItem {
    // position: absolute;
    width: 341px;
    height: 127px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    // padding-left: 13px;
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-right: 14px;
    overflow: hidden;

    .imgs {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: auto;
      padding-left: 13px;
      overflow: auto;
      margin-bottom: -5px;
    }

    .h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: #1F83FE;
      white-space: nowrap;
      padding-left: 13px;
    }

    .p {
      width: 100%;
      font-size: 14px;
      line-height: 140%;
      padding-left: 13px;
    }
  }

  .btn-group-swipe {
    position: absolute;
    right: 32px;
    display: flex;
    flex-direction: row;
    gap: 26px;
    overflow: hidden;

    .back-btn-swipe {
      width: 46px;
      height: 46px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      border: 0;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    .back-btn-swipe:disabled {
      opacity: 0.5;
    }
    .next-btn-swipe {
      width: 46px;
      height: 46px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      border: 0;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    .next-btn-swipe:disabled {
      opacity: 0.5;
    }

  }

  .map-uk {
    width: 100%;
    height: 744px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;

    .map-img {
      position: absolute;
      display: flex;
    }

    .map-img-mob {
      display: none;
    }

    .text {
      margin-top: -80px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 81px;

      h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
      }

      .map-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;

        .item {
          width: 219.2px;
          height: 100px;
          background: #FFFFFF;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;

          h2 {
            font-weight: 700;
            font-size: 39px;
            line-height: 120%;
          }
        }
      }
    }
  }

  .anim {
    width: 100%;
    height: 539px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 4%;
    overflow: auto;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 34px;
      margin-top: -30px;

      h2 {
        width: 80%;
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
      }

      button {
        width: 159px;
        height: 40px;
        background: #1F83FE;
        box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        border: 0;
        cursor: pointer;
      }
      button:hover {
        background: #57A2FE;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      gap: 15px;

      .anim-points {
        width: 60px;
        padding-top: 73px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .first {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: #ffffff;
          border: 12px solid #1F83FE;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          animation: pulse 8s ease-in-out infinite;
        }

        @keyframes pulse {
          0% {
            transform: scale(1);
          }
          12% {
            transform: scale(1.3);
          }
          25% {
            transform: scale(1);
          }
          100% {
            transform: scale(1);
          }
        }

        .vector {
          width: 2px;
          background-color: #1F83FE;
          height: 33px;
        }
        .between {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #1F83FE;
          opacity: 0.5;
        }

        .vector2 {
          width: 2px;
          background-color: #1F83FE;
          height: 26px;
        }

        .second {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: #ffffff;
          border: 12px solid #4DBB7C;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          animation: pulse 8s ease-in-out infinite;
          animation-delay: 2s;
        }

        .vector3 {
          width: 2px;
          background-color: #1F83FE;
          height: 34px;
        }

        .between2 {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #4DBB7C;
          opacity: 0.5;
        }

        .third {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: #ffffff;
          border: 12px solid #4469B0;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          animation: pulse 8s ease-in-out infinite;
          animation-delay: 4s;
        }

        .vector4 {
          width: 2px;
          background-color: #1F83FE;
          height: 40px;
        }

        .between3 {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #4469B0;
          opacity: 0.5;
        }

        .fourth {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: #ffffff;
          border: 12px solid #8E9DB5;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          animation: pulse 8s ease-in-out infinite;
          animation-delay: 6s;
        }
      }

      .items-group {
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        padding-right: 55px;

        .item {
          background: #FFFFFF;  
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          border-radius: 12px;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 17px;
          gap: 13px;
        }

        .item.one {
          width: 369px;
          height: 84px;
          .img-a-color {
            padding-left: 5px;
            padding-right: 5px;
            width: 30px;
            height: 30px;
            background: #1F83FE;
            border-radius: 5px;
          }
        }
        .item.two {
          width: 433px;
          height: 84px;
          margin-top: 28px;
          .img-a-color {
            padding-left: 5px;
            padding-right: 5px;
            width: 30px;
            height: 30px;
            background: #4DBB7C;
            border-radius: 5px;
          }
        }
        .item.three {
          width: 433px;
          height: 84px;
          margin-top: 37px;
          .img-a-color {
            padding-left: 5px;
            padding-right: 5px;
            width: 30px;
            height: 30px;
            background: #4469B0;
            border-radius: 5px;
          }
        }
        .item.four {
          width: 401px;
          height: 65px;
          margin-top: 57px;
          .img-a-color {
            padding-left: 5px;
            padding-right: 5px;
            width: 30px;
            height: 30px;
            background: #8E9DB5;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .benefits {
    width: 100%;
    height: 402px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    gap: 63px;
    overflow: hidden;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 47px;

      .item {
        width: 265px;
        height: 45px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        line-height: 120%;

        .img-b-color {
          width: 40px;
          height: 40px;
          background: #FFFFFF;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          border-radius: 12px;
          padding-left: 3px;
          padding-right: 3px;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
  }

  .users {
    width: 100%;
    height: 475px;
    display: flex;
    flex-direction: row;
    padding-left: 4%;
    padding-right: 32px;
    justify-content: space-between;
    overflow: hidden;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 24px;

      h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
      }
      button {
        width: 169px;
        height: 40px;
        background: #1F83FE;
        box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        border: 0;
        cursor: pointer;
      }
      button:hover {
        background: #57A2FE;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      padding-top: 81px;
      width: 666px;

      .row {
        display: flex;
        flex-direction: row;
        height: 140px;
        gap: 16px;
        margin-top: -20px;

        .item {
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          border-radius: 12px;
          background: #FFFFFF;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          padding-top: 16px;
          text-align: center;
        }

        .item.one {
          width: 299px;
          height: 101px;
          margin-left: 24px;
        }
        .item.two {
          width: 188px;
          height: 120px;
          margin-right: 121px;
        }
        .item.three {
          width: 210px;
          height: 101px;
        }
        .item.four {
          width: 182px;
          height: 101px;
          margin-top: 16px;
        }
        .item.five {
          width: 224px;
          height: 93px;
          margin-top: 16px;
        }
        .item.six {
          width: 78px;
          height: 101px;
          margin-left: 59px;
        }
        .item.seven {
          width: 194px;
          height: 101px;
          margin-top: 16px;
        }
        .item.eight {
          width: 255px;
          height: 101px;
          margin-top: 16px;
        }
      }
    }
  }

  .cabinet {
    width: 100%;
    height: 604px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    padding-top: 80px;
    padding-left: 32px;
    padding-right: 32px;
    overflow: hidden;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
    }

    .rows {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 32px;

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:32px;
        justify-content: center;

        .item {
          width: 384px;
          height: 147px;
          background: #FAFBFF;
          border-radius: 12px;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding-top: 17px;
          padding-left: 20px;

          h3 {
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            color: #1F83FE;
          }

          p {
            font-size: 16px;
            line-height: 120%;
            
          }
        }
      }
    }

  }

  .pay-buttons {
    width: 100%;
    height: 590px;
    display: flex;
    flex-direction: row;
    padding-top: 123px;
    padding-left: 4%;
    overflow: hidden;

    .inv-img-lay {
      // position: absolute;
      // top: 4110px;
      // width: 500px;
      // height: 500px;
      margin-top: -30px;
      right: 8px;
      pointer-events: none;
      z-index: 1;
    }

    .left {
      width: 50%;
      h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        z-index: 100;
      }
  
      p {
        font-size: 24px;
        line-height: 140%;
        margin-top: 24px;
        z-index: 100;
      }
    }

    button {
      margin-top: 32px;
      width: 169px;
      height: 40px;
      background: #1F83FE;
      box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      border: 0;
      cursor: pointer;
      z-index: 100;
    }
    button:hover {
      background: #57A2FE;
    }
  }

  .pays {
    width: 100%;
    height: 604px;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    padding-top: 102px;
    justify-content: space-around;
    overflow: auto;
  
    .column {
      display: flex;
      flex-direction: column;
      gap: 95px;
      width: 45%;
  
      .item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: auto;
  
        h2 {
          font-weight: 700;
          font-size: 20px;
          line-height: 120%;
          color: #1F83FE;
        }
  
        p {
          font-size: 16px;
          line-height: 120%;
        }
      }
    }
  }

  .tariffs {
    width: 100%;
    height: 862px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 40px;
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 32px;
    }

    .pay-mob {
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      margin-top: 32px;
    }

    .blocks {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5%;
      padding-left: 10%;
      padding-right: 10%;
      margin-bottom: 45px;

      .item {
        width: 298px;
        height: 100%;
        background: #ffffff;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        padding-left: 24px;
        padding-top: 24px;
        padding-bottom: 24px;
        gap: 93px;
        padding-right: 50px;

        p {
          font-size: 16px;
          line-height: 120%;
        }

        .bot {
          display: flex;
          flex-direction: column;
          gap: 5px;

          h3 {
            font-weight: 700;
            font-size: 40px;
            line-height: 120%;
            color: #1F83FE;
            margin-bottom: 0px;
          }

          h4 {
            font-weight: 700;
            font-size: 30px;
            line-height: 120%;
            color: #1F83FE;
          }

          p {
            opacity: 0.5;
          }
        }
      }
    }

    .horizontal-block {
      height: 83px;
      width: 80%;
      background: #FFFFFF;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 23px;
      align-items: flex-end;

      p {
        font-size: 16px;
        line-height: 120%;
        opacity: 0.5;
        margin-right: 5px;
      }

      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 120%;
        color: #1F83FE;
        margin-bottom: -7px;
        margin-right: 5px;
      }
    }

    .info {
      height: 76px;
      width: 65%;
      text-align: center;
      margin-bottom: 24px;
    }

    .last-btn {
      width: 159px;
      height: 40px;
      background: #1F83FE;
      box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
      border-radius: 8px;
      border: 0;
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
    }
    .last-btn:hover {
      background: #57A2FE;
    }
  }

  .license {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    background: #ffffff;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 40px;
    }
  
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      margin-bottom: 32px;
      text-align: center;
      width: 650px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 40px;
    }

    .form-inputs{
       width: auto;
       height: auto;
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       justify-content: center;
       gap: 20px;

       .send-btn-lending {
        width: 384px;
        height: 40px;
        border: 0;
        background: #1F83FE;
        box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        cursor: pointer;
       }

       .send-btn-lending.disabled {
        background: #8fc1fe;
       }

       .send-btn-lending:hover {
        background: #57A2FE;
      }
    }
  }

  .question {
    width: 100%;
    height: 237px;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 4%;
    padding-right: 32px;
    padding-top: 57px;
    overflow: hidden;

    .block {
      width: 100%;
      height: 237px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 4%;
      padding-right: 32px;

      .left {
        width: 35%;
        margin-top: -7px;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
  
      .right {
        display: flex;
        flex-direction: row;
        gap: 32px;
  
        .column {
          display: flex;
          flex-direction: column;
          gap: 37px;
  
          .send-btn-land {
            width: 280px;
            height: 50px;
            background: #1F83FE;
            box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
            border-radius: 5px;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.03em;
            color: #FFFFFF;
            border: 0;
            cursor: pointer;
          }
          .send-btn-land:hover {
            background: #57A2FE;
          }
    
        }
      }
    }
  }

  .footter {
    width: 100%;
    height: 299px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    padding-top: 72px;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .colum {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 7%;
      padding-left: 80px;
      padding-right: 100px;

      .icons {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-right: 5%;

        .icons-row {
          display: flex;
          flex-direction: row;
          gap: 16px;
        }
      }

      .col-info {
        display: flex;
        flex-direction: column;
        gap: 14px;
        font-size: 16px;
        line-height: 120%;

        p {
          cursor: pointer;
        }
        p:hover {
          color: black;
          text-decoration: underline;
        }

        .contacts-row {
          display: flex;
          flex-direction: row;

          .touch {
            width: 24px;
            height: 24px;
          }

          .p-bold {
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
          }

          .p-thin {
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
          }
        }

        .contacts-row.gap {
          gap: 14px;
          align-items: center;
        }

        .touch {
          cursor: pointer;
        }

        h2 {
          font-weight: 700;
          font-size: 17px;
          line-height: 120%;
          color: #1F83FE;
          margin-bottom: 6px;
        }
      }
      .col-info.two {
        margin-top: 38px;
      }
    }

    .foot {
      font-size: 12px;
      line-height: 120%;
    }
  }

  .version {
    display: none;
  }
}

@media screen and (min-width: 1439px) {
  .layout {
    .header {
      width: 1440px;
      left: calc(50vw - 720px);
    }

    .main {
      position: relative;
      width: 1440px;
      margin-top: 72px;
      left: calc(50vw - 720px);
      height: 799px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: 20px;
  
      .back-img-first {
        display: none;
      }

      .back-img-big {
        display: block;
        position: absolute;
        right: -250px;
        top: -72px;
        pointer-events: none;
      }
    }
    
    .swipe-list {
      position: relative;
      left: calc(50vw - 720px);
      width: calc(100vw - (50vw - 720px));
    }
  
    .map-uk {
      margin-top: 50px;
      position: relative;
      width: 1440px;
      left: calc(50vw - 720px);
      border-radius: 20px;
      height: 744px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      overflow: hidden;
  
      .map-img {
        position: absolute;
        display: flex;
      }
  
      .map-img-mob {
        display: none;
      }
  
      .text {
        margin-top: -80px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 81px;
  
        h1 {
          font-weight: 700;
          font-size: 32px;
          line-height: 120%;
        }
  
        .map-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 30px;
          flex-wrap: wrap;
  
          .item {
            width: 219.2px;
            height: 100px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
  
            h2 {
              font-weight: 700;
              font-size: 39px;
              line-height: 120%;
            }
          }
        }
      }
    }
  
    .anim {
      position: relative;
      left: calc(50vw - 720px);
      width: 1440px;
      height: 539px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 4%;
      overflow: hidden;
  
    }
  
    .benefits {
      position: relative;
      left: calc(50vw - 720px);
      width: 1440px;
      height: 402px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 70px;
      gap: 63px;
      overflow: hidden;
      border-radius: 20px;
    }
  
    .users {
      position: relative;
      left: calc(50vw - 720px);
      width: 1440px;
    }
  
    .cabinet {
      position: relative;
      left: calc(50vw - 720px);
      width: 1440px;
      border-radius: 20px;
    }
  
    .pay-buttons {
      position: relative;
      left: calc(50vw - 720px);
      width: 1440px;
      height: 590px;
      display: flex;
      flex-direction: row;
      padding-top: 123px;
      padding-left: 4%;
      overflow: hidden;
  
      .inv-img-lay {
        // position: absolute;
        // top: 4110px;
        // width: 500px;
        // height: 500px;
        margin-top: -30px;
        right: 8px;
        pointer-events: none;
        z-index: 1;
      }

      .left {
        width: 50%;
        h2 {
          font-weight: 700;
          font-size: 32px;
          line-height: 120%;
          z-index: 100;
        }
    
        p {
          font-size: 24px;
          line-height: 140%;
          margin-top: 24px;
          z-index: 100;
        }
      }
    }
  
    .pays {
      position: relative;
      left: calc(50vw - 720px);
      width: 1440px;
      border-radius: 20px;
    
      .column {
    
        .item {
          height: 140px;
        }
      }
    }

    .question {
      width: 100%;
      height: 237px;
      background: #FFFFFF;
      padding-top: 57px;
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;

      .block {
        position: relative;
        left: calc(50vw - 720px);
        width: 1440px;
        height: 237px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;

        .left {
          width: 35%;
          margin-top: -7px;
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
    
        .right {
          display: flex;
          flex-direction: row;
          gap: 32px;
    
          .column {
            display: flex;
            flex-direction: column;
            gap: 37px;
    
            .send-btn-land {
              width: 280px;
              height: 50px;
              background: #1F83FE;
              box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
              border-radius: 5px;
              font-weight: 600;
              font-size: 16px;
              line-height: 100%;
              letter-spacing: 0.03em;
              color: #FFFFFF;
              border: 0;
              cursor: pointer;
            }
            .send-btn-land:hover {
              background: #57A2FE;
            }
      
          }
        }
      }
    }

    .license {
      position: relative;
      left: calc(50vw - 720px);
      width: 1440px;
      border-radius: 20px;
    }
    .form {
  
      .form-inputs{
         width: 800px;
  
      }
    }
  }
}

@media screen and (min-width: 1195px) {
  .layout {
    .form {
  
      .form-inputs{
         width: 800px;
  
      }
    }
  }
}

@media screen and (max-width: 1230px) {
  .layout {
    .cabinet {
      width: 100%;
      height: auto;
      padding-bottom: 50px;
      .rows {
        .row {
          flex-wrap: wrap;
        }
      }
    }
    .pay-buttons {
      height: auto;
      padding-bottom: 50px;

      .left {
        width: 50%;
        h2 {
          font-weight: 700;
          font-size: 32px;
          line-height: 120%;
          z-index: 100;
        }
    
        p {
          font-size: 24px;
          line-height: 140%;
          margin-top: 24px;
          z-index: 100;
        }
      } 

  
      .inv-img-lay {
        position: absolute;
        width: 651px;
        left: 50%;
        // top: 4110px;
        right: 8px;
        pointer-events: none;
        z-index: 1;
      }
    }
  }
}

@media screen and (max-width: 1010px) {
  .layout {
    .header {
      .group-btns {
        gap: 16px;
      }
    }
    .main {
      .back-img-analit-mob {
        display: none;
      }
    }
    .users {
      .right {
        padding-left: 30px;
      }
    }
    .tariffs {
      height: auto;
      padding-bottom: 50px;

      .blocks {
        flex-wrap: wrap;
      }
  
      .last-btn {
        margin-top: 30px;
      }
    }
    .question {
      height: auto;
      flex-direction: column;
      padding-bottom: 50px;
  
      .left {
        width: 100%;
        flex-direction: column;
        margin-bottom: 30px;
      }
  
      .right {
        justify-content: center;
      }
    }
    .footter {
      height: auto;
  
      .colum {
        flex-wrap: wrap;
  
        .col-info {
          margin-bottom: 20px;
        }
      }
    }
  }

}

@media screen and (max-width: 925px) {
  .layout {
    .header {
      .group-btns {
        padding-left: 16px;
        gap: 10px;
      }
    }
    .main {
      .works-img {
        display: none;
      }
  
      .leftContent {
        width: 50%;
      }
      .logos {
        top: 600px;
        left: 10%;
        right: 10%;
      }
    }
    .anim {
      height: auto;
      flex-direction: column;
      padding-bottom: 50px;
      .left {
        margin-top: 30px;
  
      }
    }
    .benefits {
      height: auto;
      padding-bottom: 50px;
  
      .row {
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 890px) {
  .layout {
    .main {
  
      .back-img-analit {
        display: none;
      }
  
      .coll-img {
        display: none;
      }
  
      .leftContent {
        width: 90%;
      }
      .logos {
        top: 530px;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .layout {
    width: 100vw;
    overflow-x: hidden;
    .menu-mob-land {
      position: fixed;
      display: flex;
      top: 100px;
      left: 0;
      width: 300px;
      height: 400px;
      background: #F1F3F7;
      border-radius: 0 12px 12px 0;
      transition: .3s;
      display: flex;
      flex-direction: column;
      text-overflow: ellipsis;
      padding-top: 31px;
      padding-left: 19px;
      z-index: 1000;

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 34px;
        height: 40px;

        .signIn-btn {
          width: 152px;
          height: 40px;
          border: 0;
          background: #1F83FE;
          box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
          border-radius: 8px;
          font-size: 16px;
          line-height: 20px;
          color: #FFFFFF;
          cursor: pointer;
        }
      }

      .bot {
        margin-left: auto;
        margin-top: 23px;
        width: 226px;
        height: 306px;
        background-color: #ffffff;
        border-radius: 12px 0 12px ;
        padding-top: 26px;
        padding-left: 10px;
        font-size: 16px;
        line-height: 100%;
        color: #34303E;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .phone-btn-nav {
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          background: #FAFBFF;
          border: 0;
          // cursor: pointer;
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 16px;
          color: #34303E;
        }

      }

    }

    .menu-mob-land.hide {
      left: -301px;
      transition: .3s;
    }

    .header {
      // display: none;
      width: 100%;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 10px;

      .menu-btn-mob-land {
        display: flex;
        width: 20px;
        height: 11px;
      }

      .exit-btn-mob-land {
        display: flex;
        width: 40px;
        height: 40px;
        box-shadow: 0px 8px 20px rgba(91, 136, 241, 0.32);
        border-radius: 8px;
        padding-left: 8px;
        padding-right: 8px;
        background: #1F83FE;
        position: relative;
      }

      .group-btns {
        display: none;
      }
    }
    .main {
      height: 600px;
      // display: none;
      overflow: hidden;
      .back-img-first {
        display: none;
      }
      .back-img-first-mob {
        position: absolute;
        width: 80%;
        top: 50px;
        display: flex;
        right: 0px;
        pointer-events: none;
        overflow-y: hidden;
      }
      .back-img-analit {
        display: none;
      }
  
      .back-img-analit-mob {
        display: none;
      }
  
      .works-img {
        display: none;
      }
      .logos {
        position: absolute;
        top: 370px;
        left: 16px;
        right: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
  
        .top-lane {
          display: flex;
          height: 100px;
          flex-wrap: wrap;
          gap: 10px;
        }
        .bot-lane {
          // width: 180px;
          height: 100px;
          flex-wrap: wrap;
          gap: 28px;
        }
      }

      .coll-img {
        display: none;
      }

      .leftContent {
        width: 92%;
        position: absolute;
        top: 120px;
        font-size: 16px;
  
        .h1 {
          font-weight: 800;
          font-size: 24px;
        }
      }

    }
    .swipe-list {
      // display: none;
      overflow-y: hidden;
      height: auto;
      margin-bottom: 80px;

      h1 {
        padding-left: 16px;
        padding-right: 16px;
        font-weight: 800;
        font-size: 24px;
        line-height: 120%;
      }

      p {
        width: 100%;
        font-size: 20px;
        line-height: 140%;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .Slider {
      padding-left: 16px;
      overflow: hidden;
      // display: none;
    }
    .map-uk {
      height: 800px;
      // display: none;
      overflow: hidden;
      .map-img {
        display: none;
      }

      .map-img-mob {
        display: flex;
        width: 100%;
        height: 700px;
        margin-top: 50px;
        position: absolute;
        // display: none;
      }

      .text {
        margin-top: 20px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1px;
  
        h1 {
          width: 100vw;
          padding-left: 16px;
          padding-right: 16px;
          text-align: center;
        }
  
        .map-info {
          margin-top: 32px;
          flex-direction: column;
          gap: 24px;
  
          .item {
            width: 219.2px;
            height: 100px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
  
            h2 {
              font-weight: 700;
              font-size: 39px;
              line-height: 120%;
            }
          }
        }
      }

    }
    .anim {
      // display: none;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 16px;
      padding-right: 16px;
      overflow: hidden;
      padding-bottom: 110px;
      .left {
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;
  
        h2 {
          width: 100%;
          font-size: 24px;
          text-align: center;
        }
  
        button {
          // display: none;
          position: relative;
          top:400px
        }
      }
  
      .right {
        display: flex;
        flex-direction: row;
        gap: 15px;
        margin-top: -70px;
        width: 100%;
  
        .anim-points {
          display: none;
        }
  
        .items-group {
          padding-top: 24px;
          padding-right: 0px;
          width: 100%;
          gap: 16px;

          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
          }
  
          .item.one {
            width: 100%;
          }
          .item.two {
            width: 100%;
            margin-top: 0px;
          }
          .item.three {
            width: 100%;
            margin-top: 0px;
          }
          .item.four {
            width: 100%;
            margin-top: 0px;
          }
        }
      }
    }
    .benefits {
      height: auto;
      padding-top: 32px;
      gap: 24px;
      background-color: #ffffff;
      overflow: hidden;
      padding-bottom: 50px;
  
      h2 {
        font-weight: 800;
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 41px;
      }
  
      .row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 24px;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
  
        .item {
          width: 100%;
          height: 45px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
          font-size: 16px;
          line-height: 120%;
  
          .img-b-color {
            width: 40px;
            height: 40px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
            border-radius: 12px;
            padding-left: 3px;
            padding-right: 3px;
            padding-top: 8px;
            padding-bottom: 8px;
          }
        }
      }
    }
    .users {
      height: auto;
      width: 100%;
      background-color: #fafbff;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 4%;
      padding-right: 0;
      overflow: hidden;
      padding-bottom: 50px;
  
      .left {
        margin-top: 50px;
        align-items: center;
  
        h2 {
          font-weight: 800;
          font-size: 24px;
          line-height: 120%;
          text-align: center;
          margin-right: 16px;
        }
        button {
          position: relative;
          top:440px;
        }
      }
  
      .right {
        display: flex;
        height: 400px;
        width: 100%;
        flex-direction: column;
        padding-top: 0px;
        margin-top: 20px;
        overflow-y: hidden;
  
        .row {
          display: flex;
          flex-direction: row;
          width: 648px;
          height: 120px;
          margin-top: 0;
          gap: 16px;
  
        }
      }
    }
    .cabinet {
      height: auto;
      gap: 50px;
      padding-top: 50px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 50px;
  
      h2 {
        font-weight: 800;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
      }
  
      .rows {
        flex-direction: column;
        gap: 16px;
  
        .row {
          flex-direction: column;
          gap:16px;
  
          .item {
            width: 100%;
            height: auto;
            gap: 16px;
            padding-top: 17px;
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 17px;
          }
        }
      }
  
    }
    .pay-buttons {
      height: auto;
      padding-top: 50px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 50px;
  
      .inv-img-lay {
        display: none;
      }
  
      .left {
        width: 100%;
      }
    }
    .pays {
      height: auto;
      flex-direction: column;
      padding-top: 50px;
      padding-left: 16px;
      padding-right: 16px;
      justify-content: flex-start;
      gap: 20px;
      padding-bottom: 40px;
    
      .column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    
        .item {
          display: flex;
          flex-direction: column;
          gap: 16px;
          height: auto;
        }
      }
    }
    .tariffs {
      width: 100%;
      height: auto;
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px;
      padding-left: 16px;
      padding-right: 16px;
      overflow: hidden;
  
      h2 {
        font-weight: 800;
        font-size: 24px;
        line-height: 140%;
      }
  
      h3 {
        text-align: center;
      }
  
      .blocks {
        width: 100%;
        flex-direction: column;
        gap: 16px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
  
        .item {
          width: 100%;
          .bot {
  
            h3 {
              text-align: start;
            }
  
            h4 {
              font-weight: 700;
              font-size: 30px;
              line-height: 120%;
              color: #1F83FE;
            }
  
            p {
              opacity: 0.5;
            }
          }
        }
      }
  
      .horizontal-block {
        width: 100%;
        margin-bottom: 53px;
      }
  
      .info {
        height: auto;
        width: 100%;
      }
    }
    .question {
      width: 100%;
      height: auto;
      padding-right: 16px;

      .block {
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 50px;
        gap: 23px;
        padding-bottom: 50px;
        .left {
          width: 100%;
        }
    
        .right {
          flex-direction: column;
          gap: 23px;
    
          .column {
            flex-direction: column;
            gap: 23px;
    
            .send-btn-land {
              width: 100%;
            }   
          }
        }
      }
  
    }
    .license {
    
      h3 {
        font-size: 16px;
        line-height: 100%;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }

      .license-img {
        width: 90%;
      }
    }
    .form {
  
      .form-inputs{
        width: 92vw;
  
         .send-btn-lending {
          width: 92vw;
          margin-top: 25px;
         }
  
         .send-btn-lending:hover {
          background: #57A2FE;
        }
      }
    }
    .footter {
      height: auto;
      padding-bottom: 12px;
      padding-top: 30px;
      justify-content: flex-start;
      padding-left: 16px;
      padding-right: 16px;
  
      .colum {
        flex-direction: column;
        justify-content: flex-start;
        gap: 30px;
        padding-left: 0;
        padding-right: 0;
  
        .icons {
          width: 25%;
          margin-right: 0;
        }
  
        .col-info {
  
          .contacts-row {
            display: flex;
            flex-direction: row;
            height: auto;
  
            .p-bold {
              font-weight: 700;
              font-size: 16px;
              line-height: 120%;
              white-space: nowrap;
            }
  
            .p-thin {
              font-weight: 400;
              font-size: 16px;
              line-height: 120%;
            }
          }
  
          .contacts-row.gap {
            gap: 14px;
            align-items: center;
          }
  
          .touch {
            cursor: pointer;
          }
  
          h2 {
            font-weight: 700;
            font-size: 17px;
            line-height: 120%;
            color: #1F83FE;
            margin-bottom: 6px;
          }
        }
        .col-info.two {
          margin-top: -17px;
        }
      }
  
      .foot {
        margin-top: 32px;
        font-size: 12px;
        line-height: 120%;
      }
    }
  }


}

  
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .App {
    height: 0;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: none;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #eaeef6;
    border-radius: 100px;
  }

  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
}
  
  @media screen and (max-width: 768px) {
  
    ::-webkit-scrollbar {display:none;}
  }
  
  
  
  
  